export default{
	data(){
		return {
			maxTableHeight: window.innerHeight || document.documentElement.clientHeight,
		}
	},
	mounted() {
		if(!!this.$refs.table){
			this.maxTableHeight = window.innerHeight - this.$refs.table.$el.offsetTop - 210 - 40;
			window.onresize = () => {
				return (() => {
					this.maxTableHeight = window.innerHeight - this.$refs.table.$el.offsetTop - 76 - 40;
				})()
			}
		}
	},
}